import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import classnames from 'classnames';
// import background from '@/images/background.png';
// import Register from '@/modules/Register';

// const useStyles = makeStyles({
//   container: {
//     width: '100%',
//     height: '100%',
//     'background-image': `url(${background})`,
//     'background-size': 'cover',
//     'background-repeat': 'no-repeat',
//     display: 'flex',
//     'flex-direction': 'column',
//     'justify-content': 'flex-start',
//     'align-items': 'center',
//   },
//   component: {
//     'margin-top': '160px',
//     width: '320px',
//     border: '1px solid transparent',
//   },
// });

function RegisterPage() {
  return (
    <div >
      <div>
        {/* <Register signInLink={'/login'} /> */}
        <div>注册</div>
      </div>
    </div>
  );
}

export default RegisterPage;
