import React from 'react';
import Footer from './Footer';
import Grid from '@mui/material/Unstable_Grid2';

const drawerWidth = 208;

const styles = {
  root: {
    height: 'calc(100% - 64px)',
    'background-color': '#E6E7EB',
    transition: 'margin-left 0.5s, width 0.5s',
  },
  withoutSider: {
    width: '100%',
    marginLeft: 0,
  },
  withSider: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
  },
  contentPage: {
    width: '100%',
    height: 'calc(100% - 32px)',
    'overflow-y': 'auto',
  },
};

export default function Content(props) {
  const siderStyles = props.open ? styles.withSider : styles.withoutSider;
  return (
    <Grid
      container
      sx={{
        width: '100%',
        height: 'calc(100% - 64px)',
        'background-color': '#E6E7EB',
        transition: 'margin-left 0.5s, width 0.5s',
        ...siderStyles,
      }}
      direction='column'
    >
      <Grid
        container
        sx={{
          width: '100%',
          height: 'calc(100% - 32px)',
          'overflow-y': 'auto',
        }}
      >
        {props.children}
      </Grid>
      <Footer />
    </Grid>
  );
}
