import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import Logo from '@/modules/Logo';
import Nav from './Header/Nav';
import RightContent from './Header/RightContent';

function Layout(props) {
  return (
    <Grid
      container
      direction='column'
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Grid>
        <Header
          LogoRender={() => <Logo />}
          menuRender={() => <Nav />}
          rightContentRender={() => <RightContent />}
        />
      </Grid>
      <Grid>
        <Content>{props.children}</Content>
      </Grid>
      <Grid>
        <Footer />
      </Grid>
    </Grid>
  );
}

export default Layout;
