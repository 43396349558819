import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Logo from '@/modules/Logo';
import { IconButton } from '@mui/material';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';

const styles = {
  root: {
    width: '100%',
    height: '64px',
    marginLeft: '16px',
    marginRight: '16px',
    alignItems: 'center',
    borderBottom: '1px solid gray',
    flexShrink: 0,
  },
};

export default function DrawerHeader(props) {
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Grid container direction='row' sx={styles.root}>
      <Grid xs={9}>
        <Logo />
      </Grid>
      <Grid xs={1}>
        <IconButton
          onClick={() => handleClose()}
          sx={{ 'margin-right': '16px' }}
        >
          <ChevronLeftOutlinedIcon size='large' sx={{ color: 'white' }} />
        </IconButton>
      </Grid>
    </Grid>
  );
}
