import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useProduct } from '@/providers/product';

function Logo(props) {
  const { logo } = useProduct();
  return (
    <Grid sx={{ 'align-items': 'center' }} container>
      <img src={logo} alt='logo' height='32' />
    </Grid>
  );
}

export default Logo;