import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//     height: 'calc(100% - 64px - 32px)', // 100% - header - footer
//     overflow: 'auto',
//     background: '#E6E7EB',
//   },
// }));

const Content = (props) => {
  return <Grid>{props.children}</Grid>;
};

export default Content;
