import React from 'react';
import { IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Menu as MenuIcon } from '@mui/icons-material';
import UserMenu from '@/modules/UserMenu';
import LanguageMenu from '@/modules/LanguageMenu';

const styles = {
  root: {
    width: '100%',
    color: 'black',
    height: '64px',
    padding: '0px 8px',
    alignItems: 'center',
  },

  menuButton: {
    marginRight: '16px',
    marginLeft: '16px',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    'padding-left': '16px',
    flex: 'none',
    width: '128px',
  },
  hide: {
    display: 'none',
  },
  nav: {
    flex: 'auto',
  },
  UserMenu: {
    height: '100%',
    'flex-grow': 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  lang: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
};

export default function AppLayout(props) {
  const handleLeftDrawerToggle = () => {
    if (props.handleLeftDrawerToggle) {
      props.handleLeftDrawerToggle();
    }
  };

  return (
    <Grid direction='row' sx={styles.root} container>
      <Grid xs={1}>
        <IconButton
          color='inherit'
          onClick={() => handleLeftDrawerToggle()}
          edge='start'
          sx={styles.menuButton}
        >
          <MenuIcon />
        </IconButton>
      </Grid>
      <Grid sx={styles.nav} />
      <Grid sx={styles.UserMenu}>
        <UserMenu />
      </Grid>
      <Grid sx={styles.lang}>
        <LanguageMenu />
      </Grid>
    </Grid>
  );
}
