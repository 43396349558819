import axios from 'axios';
import { message } from '@/components/Message';

const instance = axios.create({ timeout: 200 * 1000 });

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  function (error) {
    console.log(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      window.location.href = `/login?redirect=${window.location.pathname}`;
      localStorage.removeItem('user');
    } else {
      const msg = `request failed, response http code: ${error.response.status}`;
      message.error(msg);
    }

    return Promise.reject(error);
  }
);

export default instance;
