import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, OutlinedInput, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Loading from '@/components/Loading';
import { useTranslation } from 'react-i18next';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useAuth } from '@/providers/auth';
import { useProduct } from '@/providers/product';

const styles = {
  root: {
    width: '100%',
    height: '100%',
  },
  captcha: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  captchaImg: {
    width: '80px',
    height: '56px',
    backgroundColor: 'white',
  },

  label: {
    'text-transform': 'none',
  },

  notice: {
    color: 'red',
    'margin-top': '10px',
  },
};

function Login(props) {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [captchaid, setCaptchaID] = useState('');
  const [loading, setLoading] = useState(false);
  const [noticeinfo, setNoticeInfo] = useState({ show: false, msg: '' });
  const { t } = useTranslation(['common', 'prompt']);
  const { api } = useProduct();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function GetCaptcha() {
      const captchaid = await api.user.GetCaptchaID();
      setCaptchaID(captchaid);
    }
    GetCaptcha();
  }, [api]);

  async function handleChangeCaptcha() {
    const captchaid = await api.user.GetCaptchaID();
    setCaptchaID(captchaid);
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const payloads = {
        username,
        password,
        captcha,
        captchaid,
      };
      const res = await auth.signin(payloads);
      if (res.ret !== 0) {
        handleChangeCaptcha();
        setNoticeInfo({ show: true, msg: res.msg });
      } else {
        const unix = Date.now().valueOf();
        if (
          res.data.password_change_time === 0 ||
          res.data.password_change_time * 1000 < unix
        ) {
          navigate('/main/my/change-password');
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleInput = (attr, event) => {
    if (attr === 'username') {
      setUserName(event.target.value);
    } else if (attr === 'password') {
      setPassword(event.target.value);
    } else if (attr === 'captcha') {
      setCaptcha(event.target.value);
    }
    setNoticeInfo({ show: false, msg: '' });
  };

  const captchaurl = api.user.GetCaptchaLink(captchaid);
  return (
    <Grid container sx={styles.root}>
      <Grid container direction='column' sx={{ width: '100%' }} spacing={2}>
        <Grid>
          <OutlinedInput
            placeholder={t('user name')}
            required
            value={username}
            fullWidth
            autoFocus
            onChange={(value) => handleInput('username', value)}
            startAdornment={
              <InputAdornment position='start'>
                <PersonOutlineOutlinedIcon size='small' />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid>
          <OutlinedInput
            type='password'
            placeholder={t('password')}
            required
            fullWidth
            value={password}
            onChange={(value) => handleInput('password', value)}
            startAdornment={
              <InputAdornment position='start'>
                <LockOutlinedIcon size='small' />
              </InputAdornment>
            }
          />
        </Grid>

        <Grid sx={styles.captcha} container direction='row'>
          <Grid xs>
            <OutlinedInput
              placeholder={t('captcha')}
              required
              fullWidth
              value={captcha}
              onChange={(value) => handleInput('captcha', value)}
            />
          </Grid>

          <Grid sx={styles.captchaImg}>
            <img src={captchaurl} alt='captcha' onClick={handleChangeCaptcha} />
          </Grid>
        </Grid>

        {noticeinfo.show && (
          <Grid sx={styles.notice}>{t(noticeinfo.msg, { ns: 'prompt' })}</Grid>
        )}
        <Grid sx={{ marginTop: '8px' }}>
          <Button
            fullWidth
            size='large'
            variant='contained'
            color='primary'
            onClick={(e) => handleSubmit(e)}
            autoFocus={true}
          >
            {t('sign in')}
          </Button>
        </Grid>
      </Grid>
      <Loading open={loading} />
      {props.withRegister && (
        <Button variant='outlined' disableRipple={true}>
          {t('do not have an account')}?
          <Link to={props.registerLink}>{t('sign up')}</Link>
        </Button>
      )}
    </Grid>
  );
}

export default Login;
