import axios from '@/utils/axios';

export async function GetWan() {
  const ret = await axios.get('/api/cpe/network/wan');
  return ret.data;
}

export async function GetLan() {
  const ret = await axios.get('/api/cpe/network/lan');
  return ret.data;
}

export async function GetDHCP() {
  const ret = await axios.get('/api/cpe/network/dhcp');
  return ret.data;
}

export async function SetWan(params) {
  const res = await axios.put('/api/cpe/network/wan', params);
  return res.data;
}

export async function SetLan(params) {
  const res = await axios.put('/api/cpe/network/lan', params);
  return res.data;
}

export async function SetDHCP(params) {
  const res = await axios.put('/api/cpe/network/dhcp', params);
  return res.data;
}

export async function GetInterfaceStatus() {
  const res = await axios.get('/api/cpe/network/interface_status');
  return res.data;
}

export async function NetDiagnose(target) {
  const res = await axios.get(`/api/cpe/network/net_check?target=${target}`);
  return res.data;
}

export async function GetTunnel(se) {
  const res = await axios.get(`/api/cpe/vpn/tunnel/${se}`);
  return res.data;
}

export async function GetVPNStat(se) {
  const res = await axios.get(`/api/cpe/vpn/stat/${se}`);
  return res.data;
}

export async function VPNConnect(se) {
  const res = await axios.post(`/api/cpe/vpn/conn/${se}`);
  return res.data;
}

export async function VPNDisconnect(se) {
  const res = await axios.post(`/api/cpe/vpn/disconn/${se}`);
  return res.data;
}

export async function GetCloudRoutePolicyList(params) {
  const res = await axios.get(`/api/cpe/cloud/routepolicy`, { params });
  return res.data;
}

export async function GetInterfaces() {
  const res = await axios.get(`/api/cpe/network/dev`);
  return res.data;
}

export async function GetCurrentConfig() {
  const res = await axios.get(`/api/cpe/config/`);
  return res.data;
}

export async function SetCurrentConfig(params) {
  const res = await axios.put(`/api/cpe/config/`, params);
  return res.data;
}

export async function GetWifi() {
  const ret = await axios.get('/api/cpe/network/wifi');
  return ret.data;
}

export async function SetWifi(params) {
  const res = await axios.put('/api/cpe/network/wifi', params);
  return res.data;
}
