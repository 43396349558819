import axios from '@/utils/axios';

export async function GetRemoteAssist() {
  const ret = await axios.get('/api/common/v3/assist/');
  return ret.data;
}

export async function SetRemoteAssist(enabled) {
  const ret = await axios.post('/api/common/v3/assist', {
    enable: enabled,
  });
  return ret.data;
}
