import React, { useState, useContext, useEffect } from 'react';

export const ProductContext = React.createContext(null);

function ProductProvider(props) {
  const logo = props.logo;
  const [nav, setNav] = useState(props.nav);
  const api = props.api;
  const icons = props.icons;
  const [title, setTitle] = useState(props.title || '');

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <ProductContext.Provider
      value={{
        nav,
        setNav,
        logo,
        product: props.product,
        layout: props.layout,
        footer: props.footer,
        api,
        icons,
        title,
        setTitle,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
}

export function useProduct() {
  const value = useContext(ProductContext);
  return value;
}

export function useLogo() {
  const { logo } = useContext(ProductContext);
  return logo;
}

export function useAPI() {
  const { api } = useContext(ProductContext);
  return api;
}

export function useIcons() {
  const { icons } = useContext(ProductContext);
  return icons;
}

export function useConfig() {
  const { config, setConfig } = useContext(ProductContext);
  return { config, setConfig };
}

export function useTitle() {
  const { title, setTitle } = useContext(ProductContext);
  return { title, setTitle };
}

export function useNav() {
  const { nav, setNav } = useContext(ProductContext);
  return { nav, setNav };
}

export default ProductProvider;
