import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enUsCommon from './locales/en/common.json';
import zhCnCommon from './locales/zh/common.json';
import enUsPrompt from './locales/en/prompt.json';
import zhCnPrompt from './locales/zh/prompt.json';

const resources = {
  en: {
    common: enUsCommon,
    prompt: enUsPrompt,
  },
  zh: {
    common: zhCnCommon,
    prompt: zhCnPrompt,
  },
};

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
i18n.changeLanguage('zh');
export default i18n;
