import axios from '@/utils/axios';

export async function GetProxyMode() {
  const res = await axios.get('/api/nayc/v1/proxy/mode');
  return res.data;
}

export async function SetProxyMode(params) {
  const ret = await axios.put('/api/nayc/v1/proxy/mode', params);
  return ret.data;
}

export async function GetConnect() {
  const res = await axios.get('/api/nayc/v1/proxy/connect');
  return res.data;
}

export async function SetupConnect(params) {
  const res = await axios.post('/api/nayc/v1/proxy/connect', params);
  return res.data;
}

export async function GetUserAccountInfo() {
  const ret = await axios.get('/api/nayc/v1/proxy/accountinfo');
  return ret.data;
}

export async function GetUserFlowInfo() {
  const ret = await axios.get('/api/nayc/v1/proxy/flowinfo');
  return ret.data;
}

export async function GetCloudServers() {
  const ret = await axios.get('/api/nayc/v1/proxy/server');
  return ret.data;
}
