import * as systemAPI from './system';
import * as userAPI from './user';
import * as agentAPI from './agent';
import * as agentGroupAPI from './agentGroup';
import * as remoteAssistAPI from './remoteAssist';
import * as progressAPI from './progress';
import * as userStrategyAPI from './zerotrust/userstrategy';
import * as proxyAPI from './proxy/proxy';
import * as auditLogAPI from './auditlog/auditlog';
import * as cpeNetAPI from './cpe/network';
import * as targetAPI from './target';
import * as hostScannerAPI from './hostScanner';
import * as secretKeyAPI from './secretKey';
import * as manhourAPI from './manhour';
import * as processAPI from './hids/process';
import * as hoststatAPI from './hids/hoststat';
import * as rbacAPI from './rbac';
import * as ngfwAPI from './ngfw';
import * as v2mngAPI from './v2mng';

const apifunc = {
  remoteAssist: {
    ...remoteAssistAPI,
  },
  manhour: {
    ...manhourAPI,
  },
  system: {
    ...systemAPI,
  },
  agent: {
    ...agentAPI,
  },
  user: {
    ...userAPI,
  },
  ...progressAPI,
  ...userStrategyAPI,
  auditlog: {
    ...auditLogAPI,
  },
  proxy: {
    ...proxyAPI,
  },
  cpe: {
    ...cpeNetAPI,
  },
  target: {
    ...targetAPI,
  },
  hostScanner: {
    ...hostScannerAPI,
  },
  secretKey: {
    ...secretKeyAPI,
  },
  agentGroup: {
    ...agentGroupAPI,
  },
  process: {
    ...processAPI,
  },
  hoststat: {
    ...hoststatAPI,
  },
  rbac: {
    ...rbacAPI,
  },
  ngfw: {
    ...ngfwAPI,
  },
  v2mng: {
    ...v2mngAPI,
  },
};

export default apifunc;
