import React from 'react';
import { useProduct } from '@/providers/product';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//     height: '32px',
//     'background-color': '#F8F8F8',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
// }));

function Footer(props) {
  //  const classes = useStyles();

  const { footer } = useProduct();
  return <div>{footer}</div>;
}

export default Footer;
