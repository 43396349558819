import axios from '@/utils/axios';

export async function List(options) {
  const res = await axios.get(`/api/hids/v1/hoststat/`, {
    params: options,
  });
  return res.data;
}

export async function Create(data) {
  const res = await axios.post('/api/hids/v1/hoststat/', data);
  return res.data;
}

export async function Get(id) {
  const res = await axios.get(`/api/hids/v1/hoststat/${id}`);
  return res.data;
}

export async function Edit(id, data) {
  const res = await axios.put(`/api/hids/v1/hoststat/${id}`, data);
  return res.data;
}

export async function Delete(id) {
  const api = `/api/hids/v1/hoststat/${id}`;
  const res = await axios.delete(api);
  return res.data;
}

export async function MultiDelete(ids) {
  const api = `/api/hids/v1/hoststat/multi-delete`;
  const res = await axios.post(api, {
    id: ids,
  });
  return res.data;
}
