import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Divider } from '@mui/material';
import Sider from './Sider';
import Header from './Header';
import Content from './Content';

const styles = {
  root: {
    width: '100%',
    height: '100%',
  },
};

export default function SiderLayout(props) {
  const [collapse, setCollapse] = React.useState(true);

  const handleClose = () => {
    setCollapse(false);
  };

  const handleOpen = () => {
    setCollapse(true);
  };

  return (
    <Grid direction='column' sx={styles.root}>
      <Header handleLeftDrawerToggle={() => handleOpen()} />
      <Divider light />
      <Content open={collapse}>{props.children}</Content>
      <Sider open={collapse} onClose={() => handleClose()} />
    </Grid>
  );
}
