import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useProduct } from '@/providers/product';

const styles = {
  root: {
    width: '100%',
    height: '32px',
    'background-color': '#D8D8D8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

function Footer(props) {
  const { footer } = useProduct();
  return (
    <Grid sx={styles.root} /* className={props.classes.root}*/>{footer}</Grid>
  );
}

export default Footer;
