import React from 'react';
import TopLayout from './TopLayout';
import SiderLayout from './SiderLayout';

function UserLayout(props) {
  if (props.layout === 'top') {
    return <TopLayout>{props.children}</TopLayout>;
  } else if (props.layout === 'sider') {
    return <SiderLayout>{props.children}</SiderLayout>;
  }
}

export default UserLayout;
