import React, { useState, useEffect } from 'react';
import {
  Drawer,
  List,
  ListItemText,
  Collapse,
  ListItemButton,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation } from 'react-router-dom';
import { useIcons } from '@/providers/product';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useProduct } from '../../providers/product';
import DrawerHeader from './DrawerHeader';

const drawerWidth = 208;

export default function Sider(props) {
  const { t } = useTranslation(['common', 'prompt']);
  const v = useProduct();
  const pathname = useLocation().pathname;
  const [nav, setNav] = useState(v.nav);
  const icons = useIcons();

  useEffect(() => {
    const newnav = [...v.nav];
    for (let idx = 0; idx < newnav.length; idx++) {
      const subnav = newnav[idx];
      if (!subnav.children) {
        continue;
      }

      for (let subIndex = 0; subIndex < subnav.children.length; subIndex++) {
        if (pathname.startsWith(subnav.children[subIndex].path)) {
          subnav.open = true;
        }
      }
    }

    setNav(newnav);
  }, [v.nav, pathname]);

  const handleCollapse = (menulist) => {
    menulist.open = !menulist.open;
    setNav([...nav]);
  };

  const renderMenuItem = (menuitem, index) => {
    return (
      <Link to={menuitem.path} key={index}>
        <ListItemButton
          sx={{
            '&:hover': {
              backgroundColor: '#3e3dab',
            },
            marginLeft: '32px',
          }}
          selected={menuitem.path === pathname ? true : false}
        >
          <ListItemText
            primary={t(menuitem.name)}
            sx={{
              'font-size': '0.875em',
              color: 'white',
              marginLeft: '8px',
            }}
          />
        </ListItemButton>
      </Link>
    );
  };
  const renderMenuList = (menulist, index) => {
    return (
      <div key={index}>
        <ListItemButton
          sx={{
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
          onClick={() => handleCollapse(menulist)}
        >
          <Grid
            sx={{
              width: '32px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {menulist.icon && icons[menulist.icon]}
          </Grid>
          <ListItemText
            sx={{
              fontSize: '0.875em',
              color: 'white',
              marginLeft: `8px`,
            }}
            primary={t(menulist.name)}
          />

          {menulist.open ? (
            <ExpandLess fontSize='small' color='secondary' />
          ) : (
            <ExpandMore fontSize='small' color='secondary' />
          )}
        </ListItemButton>
        <Collapse in={menulist.open} timeout={500} unmountOnExit>
          <List component='div' disablePadding>
            {menulist.children.map((subnav, subindex) => {
              if (subnav.children) {
                return renderMenuList(subnav, subindex);
              }
              /* 只支持2级路由，不支持3级路由 */
              return renderMenuItem(subnav, subindex);
            })}
          </List>
        </Collapse>
      </div>
    );
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Drawer
      variant='persistent'
      anchor='left'
      open={props.open}
      transitionDuration={500}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
          backgroundColor: '#001529',
        },
      }}
    >
      <DrawerHeader onClose={() => handleClose()}></DrawerHeader>
      <List component='nav'>
        {nav.map((menu, index) => {
          if (menu.children) {
            return renderMenuList(menu, index, 0);
          } else {
            return renderMenuItem(menu, index, 0);
          }
        })}
      </List>
    </Drawer>
  );
}
