import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Logo from './Logo';
import Title from './Title';

const ProductLogo = (props) => {

  return (
    <Grid
      container direction="row"
      sx={{
        width: '100%',
        'align-items': 'center',
      }}
    >
      <Logo />
      <Title />
    </Grid>
  );
};

export default ProductLogo;
