import axios from '@/utils/axios';

export async function List(options) {
  const res = await axios.get(`/api/host-scanner/v1/`, {
    params: options,
  });
  return res.data;
}

export async function Create(data) {
  console.log(data);
  const res = await axios.post('/api/host-scanner/v1/', data);
  return res.data;
}

export async function Edit(id, data) {
  const res = await axios.put(`/api/host-scanner/v1/${id}`, data);
  return res.data;
}

export async function Delete(id) {
  const api = `/api/host-scanner/v1/${id}`;
  const res = await axios.delete(api);
  return res.data;
}

export async function MultiDelete(ids) {
  const api = `/api/host-scanner/v1/multi-delete`;
  const res = await axios.post(api, {
    id: ids,
  });
  return res.data;
}
