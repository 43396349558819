let nav = [
  {
    path: '/main/dashboard',
    name: 'dashboard',
    icon: 'dashboard',
    children: [
      {
        path: `/main/dashboard/summary`,
        name: 'summary',
      },
      {
        path: `/main/dashboard/job`,
        name: 'job',
      },
    ],
  },
  {
    path: '/main/cluster',
    name: 'cluster',
    icon: 'cluster',
    children: [
      {
        path: `/main/cluster/master`,
        name: 'master',
      },
      {
        path: `/main/cluster/agent`,
        name: 'agent',
      },
    ],
  },
  {
    path: '/main/v2mng',
    name: 'v2 manager',
    icon: 'ztra',
    children: [
      {
        path: `/main/v2mng/client-download`,
        name: 'client download',
      },
      {
        path: `/main/v2mng/subscribe`,
        name: 'subscribe',
      },
    ],
  },
  {
    path: '/main/zero-trust',
    name: 'zero trust',
    icon: 'ztra',
    children: [
      {
        path: `/main/zero-trust/user-strategy`,
        name: 'user strategy',
      },
    ],
  },
  {
    path: '/main/hids',
    name: 'host security',
    icon: 'hostsec',
    children: [
      {
        path: `/main/hids/summary`,
        name: 'summary',
      },
    ],
  },
  {
    path: '/main/scan',
    name: 'scan',
    icon: 'radar',
    children: [
      {
        path: `/main/scan/address`,
        name: 'address manager',
      },
      {
        path: `/main/scan/featurescan`,
        name: 'feature scan',
      },
      {
        path: `/main/scan/taskmanager`,
        name: 'task manager',
      },
      {
        path: `/main/scan/scanner`,
        name: 'scanner manager',
      },
    ],
  },
  {
    path: '/main/icg',
    name: 'icg',
    icon: 'app',
    children: [
      {
        path: `/main/icg/access-list`,
        name: 'access list',
      },
    ],
  },
  {
    path: '/main/cpe',
    name: 'CPE',
    icon: 'router',
    children: [
      {
        path: `/main/cpe/run-status`,
        name: 'run status',
      },
      {
        path: `/main/cpe/network`,
        name: 'network setting',
      },
      {
        path: `/main/cpe/wifi`,
        name: 'wifi setting',
      },
      {
        path: `/main/cpe/ise`,
        name: 'ise',
      },
      {
        path: `/main/cpe/pse`,
        name: 'pse',
      },
      {
        path: `/main/cpe/cloud-route-policy`,
        name: 'route policy',
      },
    ],
  },
  {
    path: '/main/firewall',
    name: 'firewall',
    icon: 'firewall',
    children: [
      {
        path: `/main/firewall/session-log`,
        name: 'session log',
      },
      {
        path: `/main/firewall/alproto-log`,
        name: 'alproto log',
      },
    ],
  },
  {
    path: '/main/system-log',
    name: 'system log',
    icon: 'log',
    children: [
      {
        path: `/main/system-log/audit-log`,
        name: 'operate log',
      },
    ],
  },
  {
    path: '/main/system',
    name: 'system',
    icon: 'system',
    children: [
      {
        path: `/main/system/sysinfo`,
        name: 'system info',
      },
      {
        path: `/main/system/setting`,
        name: 'setting',
      },
      {
        path: `/main/system/shutdown-reboot`,
        name: 'shutdown reboot',
      },
      {
        path: `/main/system/remote-assist`,
        name: 'remote assist',
      },
      {
        path: `/main/system/network`,
        name: 'network',
      },
      {
        path: `/main/system/users`,
        name: 'user manager',
      },
      {
        path: `/main/system/rbac`,
        name: 'RBAC',
      },
      {
        path: `/main/system/license`,
        name: 'license',
      },
    ],
  },
  {
    path: '/main/my',
    name: 'my',
    icon: 'user',
    children: [
      {
        path: `/main/my/change-password`,
        name: 'change password',
      },
    ],
  },
  {
    path: '/main/tools',
    name: 'tools',
    icon: 'tool',
    children: [
      {
        path: `/main/tools/manhour`,
        name: 'man hour',
      },
      {
        path: `/main/tools/secret-key`,
        name: 'secret key manager',
      },
      {
        path: `/main/tools/md5`,
        name: 'md5',
      },
    ],
  },
];

export default nav;
