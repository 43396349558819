import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import App1 from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
if (process.env.REACT_APP_PROD === 'prod') {
  /*这里加载其他产品*/
  root.render(
    <React.StrictMode>
      <App1 />
    </React.StrictMode>
  );
} else {
  root.render(
    <React.StrictMode>
      <App1 />
    </React.StrictMode>
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
