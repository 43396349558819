import React, { useState } from 'react';
import { Menu, MenuItem, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/providers/auth';

function UserMenu(props) {
  const { user, signout } = useAuth();
  const [username] = useState(user.username);

  const { t } = useTranslation(['common', 'prompt']);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userLogout = async () => {
    try {
      await signout();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Button
        variant='text'
        onClick={handleClick}
        sx={{
          'text-transform': 'none',
          color: 'inherit',
          height: '100%',
          '&:hover': {
            color: '#40a9ff',
          },
        }}
      >
        {username}
      </Button>
      <Menu
        getContentAnchorEl={null}
        onClose={handleClose}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={userLogout}>{t('sign out')}</MenuItem>
      </Menu>
    </div>
  );
}

export default UserMenu;
