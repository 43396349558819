import axios from '@/utils/axios';

export async function NameList(options) {
  const res = await axios.get(`/api/secret-key/v1/name-list/`, {
    params: options,
  });
  return res.data;
}

export async function List(options) {
  const res = await axios.get(`/api/secret-key/v1/`, {
    params: options,
  });
  return res.data;
}

export async function Create(data) {
  const res = await axios.post('/api/secret-key/v1/', data);
  return res.data;
}

export async function Edit(id, data) {
  const res = await axios.put(`/api/secret-key/v1/${id}`, data);
  return res.data;
}

export async function Get(id) {
  const res = await axios.get(`/api/secret-key/v1/${id}`);
  return res.data;
}

export async function Delete(id) {
  const api = `/api/secret-key/v1/${id}`;
  const res = await axios.delete(api);
  return res.data;
}
