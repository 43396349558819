import axios from '@/utils/axios';

export async function List(options) {
  const res = await axios.get(`/api/common/v3/manhour/`, {
    params: options,
  });
  return res.data;
}

export async function Create(data) {
  const res = await axios.post('/api/common/v3/manhour/', data);
  return res.data;
}

export async function Get(id) {
  const res = await axios.get(`/api/common/v3/manhour/${id}`);
  return res.data;
}

export async function Edit(id, data) {
  const res = await axios.put(`/api/common/v3/manhour/${id}`, data);
  return res.data;
}

export async function Delete(id) {
  const api = `/api/common/v3/manhour/${id}`;
  const res = await axios.delete(api);
  return res.data;
}

export async function MultiDelete(ids) {
  const api = `/api/common/v3/manhour/multi-delete`;
  const res = await axios.post(api, {
    id: ids,
  });
  return res.data;
}
