import axios from '@/utils/axios';

export async function SessionLogList(options) {
  const res = await axios.get(`/api/ngfw/v1/session/log/`, {
    params: options,
  });
  return res.data;
}

export async function DPIHTTPLogList(options) {
  const res = await axios.get(`/api/ngfw/v1/dpi/http-log/`, {
    params: options,
  });
  return res.data;
}

export async function DPIDNSLogList(options) {
  const res = await axios.get(`/api/ngfw/v1/dpi/dns-log/`, {
    params: options,
  });
  return res.data;
}

export async function DPITLSLogList(options) {
  const res = await axios.get(`/api/ngfw/v1/dpi/tls-log/`, {
    params: options,
  });
  return res.data;
}
