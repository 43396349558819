import React, { useState } from 'react';
import {
  useLocation,
  Navigate,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useAPI } from '@/providers/product';

let AuthContext = React.createContext(null);

export function AuthProvider(props) {
  let storeUser = null;
  const userJson = localStorage.getItem('user');
  if (userJson) {
    storeUser = JSON.parse(userJson);
  }
  let [user, setUser] = useState(storeUser);
  const api = useAPI();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const signin = async (payload) => {
    const res = await api.user.UserLogin(payload);
    if (res.ret === 0) {
      setUser(res.data);
      localStorage.setItem('user', JSON.stringify(res.data));
      const redirect = searchParams.get('redirect');
      if (redirect) {
        setTimeout(() => {
          navigate(redirect);
        }, 1000);
      } else {
        navigate('/');
      }
    }
    return res;
  };

  let signout = async () => {
    await api.user.Logout();
    localStorage.removeItem('user');
    setUser(null);
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ user, signin, signout }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth(props) {
  let { user } = useAuth();
  let location = useLocation();

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={`/login?redirect=${location.pathname}`} />;
  }

  return props.children;
}
