import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import bgpic from '@/images/background.png';
import Login from '@/modules/Login';

function LoginPage() {
  return (
    <Grid
      container
      direction='column'
      sx={{
        width: '100%',
        height: '100%',
        backgroundImage: `url(${bgpic})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        sx={{
          marginTop: '120px',
          width: '480px',
          border: '1px solid transparent',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Login withRegister={false} />
      </Grid>
    </Grid>
  );
}

export default LoginPage;
