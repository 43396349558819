import axios from '@/utils/axios';

export async function FetchUserRules(params) {
  const res = await axios.get('/api/zerotrust/v1/userstrategy', {
    params,
  });
  return res.data;
}

export async function CreateUserRule(params) {
  const res = await axios.post('/api/zerotrust/v1/userstrategy', params);
  return res.data;
}

export async function DeleteUserRule(id) {
  const res = await axios.delete(`/api/zerotrust/v1/userstrategy/${id}`);
  return res.data;
}
