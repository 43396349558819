import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import Loading from '@/components/Loading';

export const AppContext = React.createContext(null);

function GetAppTheme() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#4341ee',
      },
      secondary: {
        main: '#FFFFFF',
      },
    },
  });
  return theme;
}

export default function AppProvider(props) {
  const [events, setEvents] = useState([]);
  const theme = GetAppTheme();

  return (
    <React.Suspense fallback={<Loading open={true} />}>
      <AppContext.Provider value={{ events, setEvents }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>{props.children}</BrowserRouter>
        </ThemeProvider>
      </AppContext.Provider>
    </React.Suspense>
  );
}

export function useAppStyles() {
  const { appStyles } = useContext(AppContext);
  return appStyles();
}

export function useSubscribe(ev, eventHandler) {
  const { events, setEvents } = useContext(AppContext);

  useEffect(() => {
    const index = events.findIndex(({ event }) => event === ev);

    if (index !== -1) {
      // 这里获取event事件名称，由于后续语句不需要，忽略掉
      const { data } = events[index];
      events.splice(index, 1);
      //setEvents([...events]);
      eventHandler(data);
    }
  }, [events, setEvents, ev, eventHandler]);
}

export function useEmit() {
  const { events, setEvents } = useContext(AppContext);
  const emitEvent = (event, data) => {
    events.push({ event, data });
    setTimeout(() => {
      setEvents([...events]);
    }, 1500);
  };
  return emitEvent;
}
