import axios from '@/utils/axios';

export async function Reboot() {
  await axios.post(`/api/system-setting/v1/reboot`);
}

export async function GetDeviceInfo() {
  const res = await axios.get(`/api/system-setting/v1/device/`);
  return res.data;
}

export async function GetStatus() {
  const res = await axios.get(`/api/system-setting/v1/status/`);
  return res.data;
}

export async function GetLang() {
  const res = await axios.get(`/api/system-setting/v1/locale`);
  return res.data;
}

export async function ChangeLang(lang) {
  const res = await axios.put(`/api/system-setting/v1/locale`, {
    lang: lang,
  });
  return res.data;
}

export async function Shutdown() {
  await axios.post(`/api/system-setting/v1/shutdown`);
}

export async function GetNetConfig() {
  const res = await axios.get(`/api/network/v1/`);
  return res.data;
}

export async function SetNetConfig(data) {
  const res = await axios.put(`/api/network/v1/`, data);
  return res.data;
}

export async function SetWAC(data) {
  const res = await axios.post(`/api/wac/v1/`, data);
  return res.data;
}

export async function GetWAC() {
  const res = await axios.get(`/api/wac/v1/`);
  return res.data;
}
