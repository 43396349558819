import React from 'react';
import { Grid, Badge, IconButton } from '@mui/material';
import Notifications from '@mui/icons-material/Notifications';
import UserMenu from '@/modules/UserMenu';
import LanguageMenu from '@/modules/LanguageMenu';

function RightContent() {
  return (
    <Grid container direction='row' alignItems='center' justify='flex-end'>
      <Grid container xs={true} direction='row' alignItems='center'>
        <IconButton color='secondary'>
          <Badge badgeContent={0} color='primary'>
            <Notifications />
          </Badge>
        </IconButton>
      </Grid>
      <Grid container xs={true} direction='row' alignItems='center'>
        <UserMenu />
      </Grid>
      <Grid container xs={true} direction='row' alignItems='center'>
        <LanguageMenu />
      </Grid>
    </Grid>
  );
}

export default RightContent;
