import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import ReactDOM from 'react-dom';

function Message(props) {
  const { msg, msgtype, onClose, duration } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={true}
      autoHideDuration={duration ? duration * 1000 : null}
      onClose={handleClose}
    >
      <Alert variant='filled' onClose={handleClose} severity={msgtype}>
        {msg}
      </Alert>
    </Snackbar>
  );
}

function info(msg, duration = 2) {
  const ele = document.createElement('div');
  document.body.appendChild(ele);
  const onClose = () => {
    ReactDOM.unmountComponentAtNode(ele);
    document.body.removeChild(ele);
  };
  ReactDOM.render(
    <Message
      msg={msg}
      msgtype={'info'}
      duration={duration}
      onClose={onClose}
    />,
    ele
  );
}

function success(msg, duration = 2) {
  const ele = document.createElement('div');
  document.body.appendChild(ele);
  const onClose = () => {
    ReactDOM.unmountComponentAtNode(ele);
    document.body.removeChild(ele);
  };
  ReactDOM.render(
    <Message
      msg={msg}
      msgtype={'success'}
      duration={duration}
      onClose={onClose}
    />,
    ele
  );
}

function warning(msg, duration = 2) {
  const ele = document.createElement('div');
  document.body.appendChild(ele);
  const onClose = () => {
    ReactDOM.unmountComponentAtNode(ele);
    document.body.removeChild(ele);
  };
  ReactDOM.render(
    <Message
      msg={msg}
      msgtype={'warning'}
      duration={duration}
      onClose={onClose}
    />,
    ele
  );
}

function error(msg, duration = 2) {
  const ele = document.createElement('div');
  document.body.appendChild(ele);
  const onClose = () => {
    ReactDOM.unmountComponentAtNode(ele);
    document.body.removeChild(ele);
  };
  ReactDOM.render(
    <Message
      msg={msg}
      msgtype={'error'}
      duration={duration}
      onClose={onClose}
    />,
    ele
  );
}
export const message = {
  info,
  warning,
  error,
  success,
};

export default Message;
