import axios from '@/utils/axios';

export async function SubscribeList() {
  const res = await axios.get(`/api/v2mng/v1/subscribe/list/`);
  return res.data;
}

export async function FetchSubscribe(uuid) {
  const res = await axios.get(`/api/v2mng/v1/subscribe/get/${uuid}`);
  return res.data;
}

export async function DeleteSubscribe(uuid) {
  const res = await axios.delete(`/api/v2mng/v1/subscribe/delete/${uuid}`);
  return res.data;
}

export async function CreateSubscribe(data) {
  const res = await axios.post(`/api/v2mng/v1/subscribe/create/`, data);
  return res.data;
}
