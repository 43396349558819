import axios from '@/utils/axios';

export async function FetchProgress(id) {
  const api = `/api/common/v3/progress/${id}`;
  const res = await axios.get(api);
  return res.data;
  /*
  if (res.data.ret === 0) {
    data.total = Number(res.data.data.total);
    data.success = Number(res.data.data.success);
    data.failed = Number(res.data.data.failed);
  }
  return data;
  */
}
