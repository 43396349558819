import axios from '@/utils/axios';

export async function GetCustomAddress(params) {
  const res = await axios.get('/api/address/v1/custom', { params });
  return res.data;
}

export async function EditCustomAddress(params) {
  const res = await axios.put(`/api/address/v1/custom/${params.name}`, params);
  return res.data;
}

export async function DeleteCustomAddress(name) {
  const res = await axios.delete(`/api/address/v1/custom/${name}`);
  return res.data;
}

export async function fetchTargetByID(id) {
  let target = {
    name: '',
    content: {
      addrs: [],
    },
  };

  const params = {
    id,
  };
  try {
    const res = await axios.get('/api/dsds/scantarget/getbyid', { params });
    if (res.data.ret === 0) {
      target = res.data.data;
    }
  } catch (err) {
    console.log(err);
  }
  return target;
}

export async function fetchTargetsSummary() {
  let data = {
    count: 0,
    results: [],
  };
  try {
    const res = await axios.get('/api/dsds/scantarget/list');
    if (res.data.ret === 0) {
      data = {
        count: res.data.data.count,
        results: res.data.data.results,
      };
    }
  } catch (err) {
    console.log(err);
  }
  return data;
}

export async function Create(options) {
  const res = await axios.post('/api/address/v1/custom', options);
  return res.data;
}

export async function EditTarget(id, options) {
  const res = await axios.put(`/api/dsds/scantarget/${id}`, options);
  return res.data;
}

export async function GenerateScanTargetConfig() {
  const res = await axios.get(`/api/dsds/scantarget/generateconfig`);
  return res.data;
}

export async function fetchScanTargetDetail(params) {
  let data = {
    count: 0,
    results: [],
  };
  try {
    const res = await axios.get('/api/dsds/scantargetdetail/list', { params });
    if (res.data.ret === 0) {
      data = {
        count: res.data.data.count,
        results: res.data.data.results,
      };
    }
  } catch (err) {
    console.log(err);
  }
  return data;
}

export async function UploadFile(form) {
  const url = `/api/dsds/scantarget/importaddress`;
  const res = await axios.post(url, form, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
}

export async function UpdateGlobalAddress() {
  const res = await axios.post('/api/address/v1/global');
  return res.data;
}

export async function GetGlobalAddress(params) {
  const res = await axios.get('/api/address/v1/global', { params });
  return res.data;
}
