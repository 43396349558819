import React, { useEffect } from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { useAPI } from '@/providers/product';
import { message } from '@/components/Message';

function LanguageMenu(props) {
  const { i18n } = useTranslation();

  const { t } = useTranslation(['common', 'prompt']);
  const api = useAPI();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    async function syncLang() {
      const res = await api.system.GetLang();
      if (res.ret === 0) {
        i18n.changeLanguage(res.data);
      } else {
        message.info(res.msg);
      }
    }
    syncLang();
  }, [api.system, i18n]);

  const handleChangeLang = async (lang) => {
    const res = await api.system.ChangeLang(lang);
    if (res.ret === 0) {
      i18n.changeLanguage(lang);
    } else {
      message.info(res.msg);
    }
  };

  return (
    <div>
      <IconButton
        color='inherit'
        variant='text'
        onClick={handleClick}
      >
        <LanguageIcon color='inherit' size='small' />
      </IconButton>

      <Menu
        getContentAnchorEl={null}
        onClose={handleClose}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => handleChangeLang('en-US')}
        >
          {t('English')}
        </MenuItem>
        <MenuItem
          onClick={() => handleChangeLang('zh-CN')}
        >
          {t('Chinese')}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default LanguageMenu;
