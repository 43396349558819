import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { MenuList, MenuItem } from '@mui/material';
import { useNav } from '@/providers/product';
import { useTranslation } from 'react-i18next';

function isSubMenuSelected(submenu, pathname) {
  if (!submenu.children || !submenu.children.length) {
    return false;
  }

  for (let item of submenu.children) {
    if (item.path === pathname) {
      return true;
    }
  }
  return false;
}

function Nav() {
  const { nav } = useNav();
  const menus = nav || [];
  const pathname = useLocation().pathname;
  const { t } = useTranslation(['common', 'prompt']);
  return (
    <Grid container>
      {menus.map((submenu, index) => (
        <Grid xs={1}>
          <MenuList
            key={index}
            open={true}
            title={t(submenu.name)}
            //selected={isSubMenuSelected(submenu, pathname)}
          >
            {/* <MenuList> */}
            {submenu.children.map((item, index) => (
              <Link to={item.path} key={index}>
                <MenuItem
                  key={index}
                  selected={item.path === pathname ? true : false}
                >
                  {t(item.name)}
                </MenuItem>
              </Link>
            ))}
            {/* </MenuList> */}
          </MenuList>
        </Grid>
      ))}
    </Grid>
  );
}

export default Nav;
