import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { useNav, useProduct } from '@/providers/product';
import UserLayout from '@/layouts/UserLayout';
import { RequireAuth } from '@/providers/auth';

// v2 manager
const Subscribe = React.lazy(() => import('@/pages/V2Manager/Subscribe'));

// firewall
const SessionLog = React.lazy(() => import('@/pages/NGFW/SessionLog'));
const AppProtoLog = React.lazy(() => import('@/pages/NGFW/AppProtoLog'));

// log
const AuditLog = React.lazy(() => import('@/pages/Audit'));

// system
const Setting = React.lazy(() => import('@/pages/System/Setting'));
const SystemInfo = React.lazy(() => import('@/pages/System/SystemInfo'));
const UserManager = React.lazy(() => import('@/pages/System/UserManager'));
const ShutdownReboot = React.lazy(() =>
  import('@/pages/System/ShutdownReboot')
);
const RemoteAssist = React.lazy(() => import('@/pages/System/RemoteAssist'));
const Network = React.lazy(() => import('@/pages/System/Network'));

// me
const UserCenter = React.lazy(() => import('@/pages/UserCenter'));

// tools
const Manhour = React.lazy(() => import('@/pages/Manhour'));
const SecretKeyManager = React.lazy(() => import('@/pages/SecretKeyManager'));
const MD5 = React.lazy(() => import('@/pages/Tools/MD5'));

/* tools */

// 日志模块
function MainEntry() {
  const { layout } = useProduct();
  const { nav } = useNav();

  let home = `distributed/agent`;
  if (nav && nav.length !== 0 && nav[0].children.length !== 0) {
    home = nav[0].children[0].path;
  }

  return (
    <RequireAuth>
      <UserLayout layout={layout}>
        <Routes>
          {/* 通用模块 */}
          {/* 分布式节点模块 */}
          {/* <Route
            path={`${path}/cluster/master`}
            component={React.lazy(() => import('@/pages/Cluster/Master'))}
          />

          <Route
            path={`${path}/cluster/agent`}
            component={React.lazy(() => import('@/pages/Cluster/Agent'))}
          />
          <Route
            path={`${path}/cluster/monitor`}
            component={React.lazy(() => import('@/pages/Cluster/Monitor'))}
          />*/}
          <Route path={`v2mng/subscribe`} element={<Subscribe />} />
          <Route path={`firewall/session-log`} element={<SessionLog />} />
          <Route path={`firewall/alproto-log`} element={<AppProtoLog />} />

          {/* 日志审计模块 */}
          <Route path={`system-log/audit-log`} element={<AuditLog />} />

          {/* 零信任模块 */}
          {/* <Route
            path={`${path}/zero-trust/user-strategy`}
            component={React.lazy(() =>
              import('@/pages/ZeroTrust/UserStrategy')
            )}
          /> */}
          {/* 主机安全模块 */}
          {/* <Route
            path={`${path}/hids/process/:devid`}
            component={React.lazy(() => import('@/pages/HIDS/Process'))}
          />
          <Route
            path={`${path}/hids/summary`}
            component={React.lazy(() => import('@/pages/HIDS/Summary'))}
          /> */}

          {/* 扫描模块 */}
          {/* <Route
            path={`${path}/scan/address`}
            component={React.lazy(() => import('@/pages/Address'))}
          />
          <Route path={`${path}/scan/featurescan`} component={React.lazy(() => import('@/pages/FeatureScan'))} />
          <Route
            path={`${path}/scan/taskmanager`}
            component={React.lazy(() => import('@/pages/ScanTaskManager'))}
          />
          <Route path={`${path}/scan/scanner`} component={React.lazy(() => import('@/pages/Scanner'))} /> */}

          {/* CPE功能 */}
          {/* <Route
            path={`${path}/cpe/network`}
            component={React.lazy(() => import('@/pages/SASE/NetConfig'))}
          />
          <Route
            path={`${path}/cpe/wifi`}
            component={React.lazy(() => import('@/pages/SASE/Wifi'))}
          />
          <Route
            path={`${path}/cpe/run-status`}
            component={React.lazy(() => import('@/pages/SASE/RunStatus'))}
          />
          <Route
            path={`${path}/cpe/pse`}
            component={React.lazy(() => import('@/pages/SASE/PSE'))}
          />
          <Route
            path={`${path}/cpe/ise`}
            component={React.lazy(() => import('@/pages/SASE/ISE'))}
          />

          <Route
            path={`${path}/cpe/cloud-route-policy`}
            component={React.lazy(() =>
              import('@/pages/SASE/CloudRoutePolicy')
            )}
          /> */}

          {/* 系统管理模块 */}

          <Route path={`system/users`} element={<UserManager />} />

          <Route path={`system/sysinfo`} element={<SystemInfo />} />
          <Route path={`system/shutdown-reboot`} element={<ShutdownReboot />} />
          <Route path={`system/remote-assist`} element={<RemoteAssist />} />
          <Route path={`system/setting`} element={<Setting />} />
          {/* 
          <Route
            path={`${path}/system/role`}
            component={React.lazy(() => import('@/pages/System/Role'))}
          />
          <Route
            path={`${path}/system/license`}
            component={React.lazy(() => import('@/pages/System/License'))}
        />*/}
          <Route path={`system/network`} element={<Network />} />

          {/* 我的 */}
          <Route path={`my/change-password`} element={<UserCenter />} />

          {/* tools */}
          <Route path={`tools/manhour`} element={<Manhour />} />

          <Route path={`tools/secret-key`} element={<SecretKeyManager />} />
          <Route path={`tools/md5`} element={<MD5 />} />
          <Route
            exact
            path={`*`}
            element={<Navigate replace to={`system/users`} />}
          />
        </Routes>
      </UserLayout>
    </RequireAuth>
  );
}

export default MainEntry;
