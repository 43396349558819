import axios from '@/utils/axios';

export async function List(params) {
  const res = await axios.get('/api/common/v3/agent/', { params });
  return res.data;
}

export async function Get(id) {
  const api = `/api/common/v3/agent/${id}`;
  const res = await axios.get(api);
  return res.data;
}

export async function Create(params) {
  const res = await axios.post('/api/common/v3/agent/', params);
  return res.data;
}

export async function Edit(id, data) {
  const res = await axios.put(`/api/common/v3/agent/${id}`, data);
  return res.data;
}

export async function Delete(id) {
  const api = `/api/common/v3/agent/${id}`;
  const res = await axios.delete(api);
  return res.data;
}

export async function DeployMulti(ids) {
  const res = await axios.post('/api/common/v3/agent/deploy/', {
    ids,
  });
  return res.data;
}

export async function EditSetting(payload) {
  const res = await axios.post('/api/common/v3/master/setting', payload);
  return res.data;
}

export async function GetSetting(params) {
  const res = await axios.get('/api/common/v3/master/setting', { params });
  return res.data;
}

export async function AutoGetMaster() {
  const res = await axios.get('/api/common/v3/master/auto-master-setting');
  return res.data;
}

export async function GetProcessList(params) {
  const res = await axios.get('/api/hids/v1/process/', { params });
  return res.data;
}
