import axios from '@/utils/axios';

export async function GetCaptchaID() {
  const ret = await axios.get('/api/common/v3/captcha/');
  if (ret.data.ret === 0) {
    return ret.data.data.captchaid;
  }
  return '';
}

export function GetCaptchaLink(captchaid) {
  return `/api/common/v3/captcha/${captchaid}`;
}

export async function UserLogin(params) {
  const ret = await axios.post('/api/user/v1/login', params);
  return ret.data;
}

export async function UserRegister(params) {
  const ret = await axios.post('/api/user/v1/register', params);
  return ret.data;
}

export async function CheckAuth(params) {
  const res = await axios.get('/api/user/v1/checkauth', { params });
  return res.data;
}

export async function Logout() {
  await axios.post('/api/user/v1/logout');
}

export async function FetchUsers(params = {}) {
  const res = await axios.get('/api/user/v1/', { params });
  return res.data;
}

export async function FetchUserByID(id) {
  const res = await axios.get(`/api/user/v1/${id}`);

  return res.data;
}

export async function EditUser(params) {
  const res = await axios.put('/api/user/v1/edit', params);
  return res.data;
}

export async function CreateUser(params) {
  const res = await axios.post('/api/user/v1/', params);
  return res.data;
}

export async function DeleteUser(id) {
  const res = await axios.delete(`/api/user/v1/${id}`);
  return res.data;
}

export async function ResetPassword(params) {
  const res = await axios.post('/api/user/v1/reset-passwd', params);
  return res.data;
}

export async function ChangePassword(params) {
  const res = await axios.post('/api/user/v1/change-passwd', params);
  return res.data;
}
