import AppProvider from '@/providers/app';
import ProductProvider from './providers/product';
import api from '@/api';
import icons from '@/icons';
import logo from './images/logo.png';
import Entry from './entries';
import nav from './nav';

function App() {
  return (
    <AppProvider>
      <ProductProvider
        api={api}
        icons={icons}
        title={'Home'}
        logo={logo}
        product={'Nay'}
        layout={'sider'}
        footer={'nay'}
        nav={nav}
      >
        <Entry />
      </ProductProvider>
    </AppProvider>
  );
}

export default App;
