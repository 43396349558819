import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import LoginEntry from './LoginEntry';
import RegisterEntry from './RegisterEntry';
import MainEntry from './MainEntry';
import { AuthProvider } from '@/providers/auth';

function Entry() {
  return (
    <AuthProvider>
      <Routes>
        <Route path='/login' element={<LoginEntry />} />
        <Route path='/register' element={<RegisterEntry />} />

        <Route path='/main/*' element={<MainEntry />} />

        {/* <Redirect exact from='/' to={`/main`} /> */}
        <Route path='/' element={<Navigate replace to='/main' />} />
      </Routes>
    </AuthProvider>
  );
}

export default Entry;
