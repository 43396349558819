import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Hidden } from '@mui/material';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//     height: '64px',
//     padding: '0 16px',
//     color: 'white',
//     'background-color': '#001529',
//     display: 'flex',
//     justifyContent: 'flex-start',
//     alignItems: 'center',
//     'flex-wrap': 'nowrap',
//   },
//   logo: {
//     height: '100%',
//     display: 'flex',
//     alignItems: 'center',
//     'margin-right': '24px',
//     flex: 'none',
//   },
//   nav: {
//     width: '100%',
//     height: '100%',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     flex: '1 1 auto',
//   },
//   rightcontent: {
//     height: '100%',
//     display: 'flex',
//     justifyContent: 'flex-end',
//     alignItems: 'center',
//     flex: 'none',
//   },
// }));

const Header = (props) => {
  return (
    <Grid
      container
      sx={{
        height: '64px',
        color: 'white',
        'background-color': '#001529',
        alignItems: 'center',
      }}
    >
      <Hidden xsDown>
        <Grid>
          <div /*className={classnames(classes.logo)}*/>
            {props.LogoRender()}
          </div>
        </Grid>
      </Hidden>
      <Grid>
        <div /*className={classnames(classes.nav)}*/>{props.menuRender()}</div>
      </Grid>
      <Grid>
        <div /*className={classnames(classes.rightcontent)}*/>
          {props.rightContentRender()}
        </div>
      </Grid>
    </Grid>
  );
};

export default Header;
