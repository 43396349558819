import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useProduct } from '@/providers/product';

function Title() {
  const { product } = useProduct();
  return (
    <Grid
      sx={{
        color: 'white',
        'font-size': '16px',
        'margin-left': '12px',
        flex: 'none',
      }}
    >
      {product}
    </Grid>
  );
}

export default Title;
