import React from 'react';
import ComputerIcon from '@mui/icons-material/Computer';
import PersonIcon from '@mui/icons-material/Person';
import BuildIcon from '@mui/icons-material/Build';
import DescriptionIcon from '@mui/icons-material/Description';
import RouterIcon from '@mui/icons-material/Router';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import SecurityIcon from '@mui/icons-material/Security';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import AppsIcon from '@mui/icons-material/Apps';
import AddModeratorIcon from '@mui/icons-material/AddModerator';

const icons = {
  system: <ComputerIcon size='small' color='secondary' />,
  user: <PersonIcon size='small' color='secondary' />,
  tool: <BuildIcon size='small' color='secondary' />,
  log: <DescriptionIcon size='small' color='secondary' />,
  router: <RouterIcon size='small' color='secondary' />,
  radar: <TrackChangesIcon size='small' color='secondary' />,
  hostsec: <SecurityIcon size='small' color='secondary' />,
  ztra: <VerifiedUserIcon size='small' color='secondary' />,
  dashboard: <DashboardIcon size='small' color='secondary' />,
  cluster: <CloudCircleIcon size='small' color='secondary' />,
  app: <AppsIcon size='small' color='secondary' />,
  firewall: <AddModeratorIcon size='small' color='secondary' />,
};

export default icons;
